export const QS_HOW_IT_WORKS_DATA = [
    {
        background:'bg-light-green',
        spacing: 'pt-3 pb-3',
        super: 'Step 1',
        title: 'Learn by doing',
        content: [
            'The best way to learn maths is to do maths. Maths (unlike some other subjects) isn\'t about memorisation. It is about problem-solving. The only way you get better at problem-solving is... solving problems — makes sense right? ',
            'So when you use QuickSense the main thing you will do is solve problems. We have over 2,000 problem questions covering each concept in detail from fundamentals (easy) to hardest exam-styled questions. ',
            'We also have videos showing you methods and techniques, you can watch these if you get stuck. ',
            'Questions are great, but every textbook has thousands of questions — what else is new?'

        ]
    },
    {
        background: 'bg-light-pink',
        spacing: 'pt-3 pb-3',
        super: 'Step 2',
        title: 'Learn from your mistakes',
        content: [
            'Every single one of our questions has step by step explanations that show up instantly. We even highlight common mistakes that students make. ',
            'There is no point working through questions one by one, checking the answer and moving on. You\'re just reinforcing what you know (feels good) and not learning what you don\'t know (feels bad). ',
            'When you get something wrong you can immediately read through the detailed explanation to find out where you made the mistake and how to fix it for next time. This is where the best learning happens, those \'Oh I should have done it this way\' moments.',
            'The questions. The answers. And the instant feedback. Straightforward and effective — but how does that save time?'
        ]
    },
    {
        spacing: 'pt-3 pb-3',
        super: 'Step 3',
        title: 'Automatically focus on what you don\'t know',
        content: [
            'Working through questions can be a waste of time if you keep doing easy questions or covering concepts you can nail in your sleep.',
            'So we built a learning algorithm that learns what your individual strengths and weaknesses are and adapts the questions toward them.<br/>',
            '<strong>This is how it works:</strong><br/>',
        ],
        listContent: [
            'You work through the different topics and answer questions. ',
            'The algorithm identifies your strengths and weaknesses based on your answers and the type of question.',
            'You get given more questions in the areas you\'re weak at until it you can master it.'
        ]
    },
    {
        background: 'bg-light-pink',        
        super: 'Step 4',
        spacing: 'pt-3 pb-3',
        title: 'Get reminded of concepts just as you\'re about to forget them',
        content: [
            'Over time you forget things. Actually, you forget them exponentially. When you learning something, after one hour you forget 50% of what you\'ve learned.', 
            'After 24 hours you forget 70% and after one week you forget 90%! This is called the forgetting curve and it\'s the reason why most traditional studying methods are super inefficient and largely a waste of time.',
            'However, we can beat the forgetting curve. If you revise what you\'ve learned at the right times it actually reduces the amount you forget and slows down how quickly you forget. This is the idea behind flash cards.',
            '<strong>QuickSense actually beats the forgetting curve by combining two effects.</strong><br/><br/>',
        ],
        listContent:[
            '<strong>The Spacing Effect/Spaced Repetition:</strong> the algorithm spaces out questions based on the forgetting curve, giving you questions for a concept just as you\'re about to forget it. <br/><br/>',
            '<strong>The Testing Effect:</strong> Instead of just giving you passive information, you have to actively answer questions. This magnifies the impact of the Spacing Effect and leads to better encoding to long-term memory.'
        ],
    },
    {
        background: 'bg-light-green',
        spacing: 'pt-3 pb-3',
        super: 'Step 5',
        title: 'Utilise other neuroscience techniques to get an unfair advantage',
        content: [
            '<h3>a. Microlearning</h3>',
            'Starting to study is often the hardest part of studying and leads to a lot of procrastination. But if you break down studying (and all big projects!) into small chunks it\'s way easier to start. It\'s kind of like how it\'s easier to binge watch 4 episodes of a TV show than to commit to watching a movie.',
            'To use this effect, we\'ve only made each lesson have a maximum of 10 questions that take about 10-15 minutes to complete. This makes it way easier to start studying and continue with it.',
            'Also, you get a sense of accomplishment when you complete a lesson or two and don\'t always feel like you\'re not doing enough. <br/><br/>',
            '<h3>b. Meta-learning</h3>',
            'Meta-learning is just a fancy way of saying \'reflecting on your own learning\' or \'learning about your learning.\' Our research has found that when students reflect on why they got the question wrong it leads to better long-term learning improvement. ',
            'This is why at the end of each incorrect answer we ask why you think the question is wrong (read the question wrong, didn\'t know, silly mistake etc). In the future, we\'ll be releasing a feature where you can not only see which questions you got wrong, but why you got them wrong. '

        ]
    },

]