import React from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//Components
import SEO from '../components/SEO/seo'
import Button from '../components/Button/button'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'
import GetStarted from  '../components/GetStarted/getStarted'

//utils
import { QS_HOW_IT_WORKS_DATA } from '../utils/pages/qsHowItWorksPage'

class ProcessPage extends React.Component {

    __renderHeroSection() { 
        return <div>
            <div className={'d-flex align-items-center process-hero mt-5 py-5'} data-sal='fade' data-sal-duration='800' data-sal-delay='1200' data-sal-easing='ease-out-quad'>                
                <Container className='pt-5'>
                    <Row>
                        <Col as='section' lg={6}>
                            <span className='text-secondary'>HOW IT WORKS</span>
                            <h1>A fully guided path to an A*- A </h1>
                            <p>Everyone talks about studying smarter not harder – but how do you do that? <br/><br/> Our guided program shows you how.</p>
                            <Button classes={'mb-4'} primary>Try for free</Button>
                        </Col>
                        <Col as='figure' lg={6}>
                            <img width='405' height='540' className='w-100' src='https://res.cloudinary.com/dtkzddu1x/image/upload/v1628548950/QuickSense/A__QS_Illustration.svg' alt='Students preparing with QuickSense for their A Grade' />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    render(){
        return <>
            <SEO title='How it works'/>
            <Layout classes='fw-landing-page'>
                {this.__renderHeroSection()}
                {
                    QS_HOW_IT_WORKS_DATA.map((section, index) => (
                        <Section
                            key={index} 
                            animation={section.animation}
                            spacing={section.spacing}
                            background={section.background}
                            title={section.title}
                            titleClasses={section.titleClasses}
                            superTitle={section.super}
                            content={section.content}
                            listContent={section.listContent}
                            contentImage={section.contentImage}
                            contentImageWidth={section.contentImageWidth}
                            contentImageHeight={section.contentImageHeight}
                            contentImageClass={section.contentImageClass}
                            contentImageWebp={section.contentImageWebp}
                            contentImageAlt={section.contentImageAlt}
                        />
                    ))
                }
                <GetStarted
                    background='bg-light-blue'
                    buttonVariant='primary'
                    secondaryButton={true}
                    title={`All you need to do is answer questions `}
                    description={`This might be a lot to take in, but if you use QuickSense you don't need to worry about it. Our algorithm has this built-in and does it all for you. <br/><br/> All you need to do is follow our program and answer the questions.`}
                    secondaryButtonText={`Just answer questions and you will get an A - A* - guaranteed`}
                />
            </Layout>
        </>
    }
}

export default ProcessPage